<template>
    <div class="instructor-details-desc">
        <div  v-if="!isLoading">
            <h3 class="text-center">Course on Progress</h3>
            <div class="row justify-content-center pt-2" v-if="progress.lenght">
                <div class="col-md-3" v-for="course in completed" :key="course.id">
                    <router-link :to="{ name: 'CourseDetails', params: {id: course.id}}">
                        <div class="single-instructor-member mb-30">
                            <div class="member-image">
                                <img src="@/assets/img/courses/1.jpg" alt="images">
                            </div>
                            <div class="member-content">
                                <h6><router-link :to="{ name: 'CourseDetails', params: {id: course.id}}">{{ course.title }}</router-link></h6>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="pt-5 text-center alert alert-danger" v-else>
                <h6>No Course on Progress</h6>
                <router-link :to="{ name: 'Courses'}" class="btn default-btn">
                    Learn Now
                </router-link>
            </div>
            <div class="row justify-content-center pt-2" v-if="completed.lenght">
                <h3 class="text-center">Course Completed</h3>
                <div class="col-md-3" v-for="course in completed" :key="course.id">
                    <router-link :to="{ name: 'CourseDetails', params: {id: course.id}}">
                        <div class="single-instructor-member mb-30">
                            <div class="member-image">
                                <div v-if="course.cover_photo">
                                    <img v-bind:src="'https://apitraining.vipawaworks.com/' + course.cover_photo" class="image" alt="">
                                </div>
                                <div v-else>
                                    <img src="@/assets/img/courses/1.jpg" alt="images">
                                </div>
                            </div>
                            <div class="member-content">
                                <h6><router-link :to="{ name: 'CourseDetails', params: {id: course.id}}">{{ course.title }}</router-link></h6>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-else>
                <Loading />
            </div>
    </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading.vue'
export default {
    components: { Loading, },
    data(){
        return {
            isLoading: true,
            token: localStorage.getItem('user_data'),
            completed: {},
            progress: {},
            errors: {},        
        }
    },
    methods:{
        getCompletedCourses(){
            axios.post('https://apitraining.vipawaworks.com/api/course/get_all_courses_by_status/1').then(response => {
                this.completed = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },
         getUncompletedCourses(){
            axios.post('https://apitraining.vipawaworks.com/api/course/get_all_courses_by_status/0').then(response => {
                this.progress = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
    },
    created(){
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getCompletedCourses() 
        this.getUncompletedCourses()
    }
}
</script>

<style>

</style>